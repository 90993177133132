import "../styles/_app.scss";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import EgretTheme from "./EgretLayout/EgretTheme/EgretTheme";
import AppContext from "./appContext";
import history from "history.js";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import routes from "./RootRoutes";
import { createStore } from "./redux";
import Auth from "./auth/Auth";
import EgretLayout from "./EgretLayout/EgretLayout";
import AuthGuard from "./auth/AuthGuard";

const store = createStore(undefined, history);

if(process.env.REACT_APP_BACKEND_BASE_URL.startsWith("https://app.z")) {
  Sentry.init({
    dsn: "https://393c802f9c794278a664b646d7e73d8f@o4504120632737792.ingest.sentry.io/4504120637194241",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const App = () => {
  console.log('base URL:', process.env.REACT_APP_BACKEND_BASE_URL);
  return (
    <AppContext.Provider value={{ routes }}>
      <Provider store={store}>
        <EgretTheme>
          <Auth>
            <BrowserRouter basename={process.env.REACT_APP_BASE_URL}>
              <AuthGuard>
                <EgretLayout />
              </AuthGuard>
            </BrowserRouter>
          </Auth>
        </EgretTheme>
      </Provider>
    </AppContext.Provider>
  );
};

export default App;
