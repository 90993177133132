import React, { Fragment, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { setUserData } from "../redux/slices/userSlice";
import localStorageService from "../services/localStorageService";

function Auth(props) {
  const { children } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorageService.getItem("auth_user")) {
      dispatch(setUserData(localStorageService.getItem("auth_user")));
    }
  }, [dispatch]);

  return (
    <Fragment>{children}</Fragment>
  )
}

export default Auth;
