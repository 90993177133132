import React, { useEffect, useState } from 'react';
import { Select, MenuItem, FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'app/config';

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
	},
	root: {
		borderRadius: 3,
		backgroundColor: COLORS.BLACK,
		'& .MuiSelect-root': {
			color: COLORS.WHITE,
			padding: '10px 24px 6px 15px',
			borderRadius: 3,
		},
		'& .MuiSelect-icon': {
			color: COLORS.SUCCESS
		},
		'&.MuiInput-underline:after': {
			borderBottom: '2px solid rgba(12, 20, 31, 0.8)'
		},
		'&.MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: '2px solid rgba(0, 0, 0, 0.42)'
		},
		'& .MuiSelect-select.Mui-disabled': {
			background: '#3a495d !important'
		}
	},
}));

function EgretSelect(props) {
	const classes = useStyles();
	const { placeholder, data, className, onChange, menuClass, ...rest } = props;
	const [value, setValue] = useState(placeholder ? placeholder : '');

	useEffect(() => {
		setValue(placeholder ? placeholder : '');
	}, [placeholder]);

	const handleChange = (event) => {
		setValue(event.target.value);
		if (onChange) onChange(event.target.value);
	};

	const renderValue = (value) => {
		let find = data.find(item => item.id === value);
		return (
			<div>
				{find ? find.name : value}
			</div>
		)
	};

	return (
		<FormControl className={className}>
			<Select
				className={classes.root}
				value={value}
				onChange={handleChange}
				renderValue={(value) => renderValue(value)}
				displayEmpty={true}
				MenuProps={{
					anchorOrigin: {
						vertical: "bottom",
						horizontal: "left"
					},
					transformOrigin: {
						vertical: "top",
						horizontal: "left"
					},
					getContentAnchorEl: null,
					className: menuClass ? menuClass : 'blackPaper'
				}}
				{...rest}
			>
				{data.map((item, index) => (
					<MenuItem value={item.id ? item.id : item} key={index}>{item.name ? item.name : item}</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}

export default EgretSelect;
