import { EgretLoadable } from 'egret';

const Locations = EgretLoadable({
	loader: () => import('./locations/Locations'),
});

const Properties = EgretLoadable({
	loader: () => import('./properties/Properties'),
});

const setupRoutes = [
	{
		path: '/setup/location',
		component: Locations
	},
	{
		path: '/setup/properties',
		component: Properties
	}
];

export default setupRoutes;
