import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosAPI from "app/config/axiosAPI";

export const getAllUsers = createAsyncThunk("getAllUsers", async () => {
  try {
    const res = await axiosAPI.get('/user?activeStatus=1');
    return res.data;
  } catch (err) {
    return err.res.data;
  }
});

export const createUser = createAsyncThunk("createUser", async (user) => {
  try {
    const res = await axiosAPI.post('/user', {
      ...user
    });
    return res.data;
  } catch (err) {
    return err.res.data;
  }
});

export const updateUser = createAsyncThunk("updateUser", async (user) => {
  try {
    const res = await axiosAPI(
      {
        method: 'put',
        url: `/user/${user.id}`,
        data: { ...user }
      }
    );
    return res.data;
  } catch (err) {
    return err.res.data;
  }
});

export const deleteUser = createAsyncThunk("deleteUser", async (userId) => {
  try {
    const res = await axiosAPI.delete(`/user/${userId}`);
    return res.data.id;
  } catch (err) {
    return err.res.data;
  }
});

export const initialState = {
  data: [],
  isLoading: false,
  isError: false
}

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder.addCase(getAllUsers.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(getAllUsers.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    })
    builder.addCase(getAllUsers.rejected, (state) => {
      state.isLoading = false;
      state.isError = false;
    })
    builder.addCase(createUser.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(createUser.fulfilled, (state, { payload }) => {
      let result_data = state.data.slice(0);
      result_data.push(payload);
      return {
        ...state,
        isLoading: false,
        data: result_data,
      };
    })
    builder.addCase(createUser.rejected, (state) => {
      state.isLoading = false;
      state.isError = false;
    })
    builder.addCase(updateUser.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(updateUser.fulfilled, (state, { payload }) => {
      const { id } = payload;
      const replaceIndex = state.data.findIndex(item => item.id === id);
      const newData = [...state.data];
      newData.splice(replaceIndex, 1, payload);
      return {
        ...state,
        data: newData,
        isLoading: false
      }
    })
    builder.addCase(updateUser.rejected, (state) => {
      state.isLoading = false;
      state.isError = false;
    })
    builder.addCase(deleteUser.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(deleteUser.fulfilled, (state, { payload }) => {
      return {
        ...state,
        data: state.data.filter(item => item.id !== payload),
        isLoading: false
      };
    })
    builder.addCase(deleteUser.rejected, (state) => {
      state.isLoading = false;
      state.isError = false;
    })
  }
})

export const usersActions = usersSlice.actions

export default usersSlice.reducer