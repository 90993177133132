import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosAPI from "app/config/axiosAPI";

export const getHeader = createAsyncThunk("getHeader", async (params) => {
  try {
    const res = await axiosAPI.get(params ? `/header?${params}` : `/header`);
    return res.data;
  } catch (err) {
    return { data: {
      availableSpaces: []
    }}
  }
});

export const initialState = {
  data: {},
  isLoading: false,
  isError: false
}

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder.addCase(getHeader.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(getHeader.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    })
    builder.addCase(getHeader.rejected, (state) => {
      state.isLoading = false;
      state.isError = false;
    })
  }
})

export const headerActions = headerSlice.actions

export default headerSlice.reducer