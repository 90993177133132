import { combineReducers } from 'redux';

import layout from './layout';
import loginReducer from './slices/loginSlice';
import userReducer from './slices/userSlice';
import locationsReducer from './slices/locationsSlice';
import processorsReducer from './slices/processorsSlice';
import studiosReducer from './slices/studiosSlice';
import timelineReducer from './slices/timelineSlice';
import devicesReducer from './slices/devicesSlice';
import deviceTypesReducer from './slices/deviceTypesSlice';
import tagsReducer from './slices/tagsSlice';
import usersReducer from './slices/usersSlice';
import linkedAccountsReducer from './slices/linkedAccountsSlice';
import reportsReducer from './slices/reportsSlice';
import calendarsReducer from './slices/calendarsSlice';
import headerReducer from './slices/headerSlice';

const createAppReducer = initialState => {
  const appReducer = combineReducers({
    layout,
    login: loginReducer,
    user: userReducer,
    locations: locationsReducer,
    processors: processorsReducer,
    studios: studiosReducer,
    timeline: timelineReducer,
    devices: devicesReducer,
    deviceTypes: deviceTypesReducer,
    tags: tagsReducer,
    users: usersReducer,
    linkedAccounts: linkedAccountsReducer,
    reports: reportsReducer,
    calendars: calendarsReducer,
    header: headerReducer
  });

  return (state = initialState, action) => {
    const nextState = appReducer(state, action);
    if (action.type === '@auth/SIGN_OUT') {
      if (localStorage) {
        localStorage.clear();
      }
      return {
        ...nextState
      };
    }

    return nextState;
  };
};

export default createAppReducer;
