import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosAPI from "app/config/axiosAPI";

export const createStudio = createAsyncThunk("createStudio", async (studio) => {
  try {
    const res = await axiosAPI.post('/studios', {
      ...studio
    });
    return res.data;
  } catch (err) {
    return err.res.data;
  }
});

export const updateStudio = createAsyncThunk("updateStudio", async (studio) => {
  try {
    const res = await axiosAPI(
      {
        method: 'put',
        url: `/studio/${studio.id}`,
        data: { ...studio }
      }
    );
    return res.data;
  } catch (err) {
    return err.res.data;
  }
});

export const deleteStudio = createAsyncThunk("deleteStudio", async (studioId) => {
  try {
    const res = await axiosAPI.delete(`/studio/${studioId}`);
    return res.data.id;
  } catch (err) {
    return err.res.data;
  }
});

export const initialState = {
  data: [],
  isLoading: false,
  isError: false
}

export const studiosSlice = createSlice({
  name: 'studios',
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder.addCase(createStudio.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(createStudio.fulfilled, (state, { payload }) => {
      let result_data = state.data.slice(0);
      result_data.push(payload);
      return {
        ...state,
        isLoading: false,
        data: result_data,
      };
    })
    builder.addCase(createStudio.rejected, (state) => {
      state.isLoading = false;
      state.isError = false;
    })
    builder.addCase(updateStudio.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(updateStudio.fulfilled, (state, { payload }) => {
      const { id } = payload;
      const replaceIndex = state.data.findIndex(item => item.id === id);
      const newData = [...state.data];
      newData.splice(replaceIndex, 1, payload);
      return {
        ...state,
        data: newData,
        isLoading: false
      }
    })
    builder.addCase(updateStudio.rejected, (state) => {
      state.isLoading = false;
      state.isError = false;
    })
    builder.addCase(deleteStudio.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(deleteStudio.fulfilled, (state, { payload }) => {
      return {
        ...state,
        data: state.data.filter(item => item.id !== payload),
        isLoading: false
      };
    })
    builder.addCase(deleteStudio.rejected, (state) => {
      state.isLoading = false;
      state.isError = false;
    })
  }
})

export const processorsActions = studiosSlice.actions

export default studiosSlice.reducer