import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosAPI from "app/config/axiosAPI";

export const getTimeLine = createAsyncThunk("getTimeLine", async (params) => {
  try {
    const res = await axiosAPI.get(`/studio/${params.id}/timeline?from=${params.from}&to=${params.to}`);
    return res.data;
  } catch (err) {
    return { data: {}};
  }
});

export const initialState = {
  data: {},
  isLoading: false,
  isError: false
}

export const timelineSlice = createSlice({
  name: 'timeline',
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder.addCase(getTimeLine.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(getTimeLine.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    })
    builder.addCase(getTimeLine.rejected, (state) => {
      state.isLoading = false;
      state.isError = false;
    })
  }
})

export const timelineActions = timelineSlice.actions

export default timelineSlice.reducer