import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosAPI from "app/config/axiosAPI";

export const getAllLocations = createAsyncThunk("getAllLocations", async (locationId) => {
  try {
    const res = await axiosAPI.get('/locations');
    return res.data;
  } catch (err) {
    return err.res.data;
  }
});

export const createLocation = createAsyncThunk("createLocation", async (location) => {
  try {
    const res = await axiosAPI.post('/location', {
      ...location
    });
    return res.data;
  } catch (err) {
    return err.res.data;
  }
});

export const updateLocation = createAsyncThunk("updateLocation", async (location) => {
  try {
    const res = await axiosAPI(
      {
        method: 'put',
        url: `/location/${location.id}`,
        data: { ...location }
      }
    )
    return res.data;
  } catch (err) {
    return err.res.data;
  }
});

export const deleteLocation = createAsyncThunk("deleteLocation", async (locationId) => {
  try {
    const res = await axiosAPI.delete(`/location/${locationId}`);
    return res.data.id;
  } catch (err) {
    return err.res.data;
  }
});

export const initialState = {
  data: [],
  isLoading: false,
  isError: false
}

export const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder.addCase(getAllLocations.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(getAllLocations.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    })
    builder.addCase(getAllLocations.rejected, (state) => {
      state.isLoading = false;
      state.isError = false;
    })
    builder.addCase(createLocation.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(createLocation.fulfilled, (state, { payload }) => {
      let result_data = state.data.slice(0);
      result_data.push(payload);
      return {
        ...state,
        isLoading: false,
        data: result_data,
      };
    })
    builder.addCase(createLocation.rejected, (state) => {
      state.isLoading = false;
      state.isError = false;
    })
    builder.addCase(updateLocation.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(updateLocation.fulfilled, (state, { payload }) => {
      const { id } = payload;
      const replaceIndex = state.data.findIndex(item => item.id === id);
      const newData = [...state.data];
      newData.splice(replaceIndex, 1, payload);
      return {
        ...state,
        data: newData,
        isLoading: false
      }
    })
    builder.addCase(updateLocation.rejected, (state) => {
      state.isLoading = false;
      state.isError = false;
    })
    builder.addCase(deleteLocation.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(deleteLocation.fulfilled, (state, { payload }) => {
      return {
        ...state,
        data: state.data.filter(item => item.id !== payload),
        isLoading: false
      };
    })
    builder.addCase(deleteLocation.rejected, (state) => {
      state.isLoading = false;
      state.isError = false;
    })
  }
})

export const locationsActions = locationsSlice.actions

export default locationsSlice.reducer