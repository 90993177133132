import { createPromise as createPromiseMiddleware } from 'redux-promise-middleware';
import createAppReducer from './rootReducer';
import getInitialStateFromLocalStorage from './getInitialStateFromLocalStorage';
import { configureStore } from "@reduxjs/toolkit";

const createStore = (preloadedState = getInitialStateFromLocalStorage(), history) => {
  const promiseMiddleware = createPromiseMiddleware({
    promiseTypeSuffixes: [
      'START',
      'SUCCESS',
      'ERROR',
    ],
  });

  const appReducer = createAppReducer(preloadedState, history);

  const store = configureStore({
    reducer: appReducer,
    preloadedState: preloadedState,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }).concat(promiseMiddleware),
  });

  return store;
}

export default createStore;
