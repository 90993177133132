import React from 'react';
import { Redirect } from 'react-router-dom';

import dashboardRoutes from './views/dashboard/dashboardRoutes';
import reportsRoutes from './views/reports/ReportsRoutes';
import spacesRoutes from './views/spaces/SpacesRoutes';
import issuesRoutes from './views/issues/IssuesRoutes';
import devicesRoutes from './views/devices/DevicesRoutes';
import setupRoutes from './views/setup/setupRoutes';
import userRoutes from './views/user/UserRoutes';
import guideRoutes from './views/guide/GuideRoutes';

import sessionRoutes from './views/sessions/SessionRoutes';

const redirectRoute = [
	{
		path: '/',
		exact: true,
		component: () => <Redirect to="/signin" />,
	},
];

const errorRoute = [
	{
		component: () => <Redirect to="/session/404" />,
	},
];

const routes = [
	...sessionRoutes,
	...dashboardRoutes,
	...reportsRoutes,
	...spacesRoutes,
	...issuesRoutes,
	...devicesRoutes,
	...setupRoutes,
	...userRoutes,
	...guideRoutes,
	...redirectRoute,
	...errorRoute,
];

export default routes;
