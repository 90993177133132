import layout from './layout/initialState';
import { initialState as login } from './slices/loginSlice';
import { initialState as user } from './slices/userSlice';
import { initialState as locations } from './slices/locationsSlice';
import { initialState as processors } from './slices/processorsSlice';
import { initialState as studios } from './slices/studiosSlice';
import { initialState as timeline } from './slices/timelineSlice';
import { initialState as devices } from './slices/devicesSlice';
import { initialState as deviceTypes } from './slices/deviceTypesSlice';
import { initialState as tags } from './slices/tagsSlice';
import { initialState as users } from './slices/usersSlice';
import { initialState as linkedAccounts } from './slices/linkedAccountsSlice';
import { initialState as reports } from './slices/reportsSlice';
import { initialState as calendars } from './slices/calendarsSlice';
import { initialState as header } from './slices/headerSlice';

export const initialState = {
  login,
  layout,
  user,
  locations,
  processors,
  studios,
  timeline,
  devices,
  deviceTypes,
  tags,
  users,
  linkedAccounts,
  reports,
  calendars,
  header
};
