import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosAPI from "app/config/axiosAPI";

export const createProcessor = createAsyncThunk("createProcessor", async (processor) => {
  try {
    const res = await axiosAPI.post('/processors', {
      ...processor
    });
    return res.data;
  } catch (err) {
    return err.res.data;
  }
});

export const updateProcessor = createAsyncThunk("updateProcessor", async (processor) => {
  try {
    const res = await axiosAPI(
      {
        method: 'put',
        url: `/processor/${processor.id}`,
        data: { ...processor }
      }
    );
    return res.data;
  } catch (err) {
    return err.res.data;
  }
});

export const deleteProcessor = createAsyncThunk("deleteProcessor", async (processorId) => {
  try {
    const res = await axiosAPI.delete(`/processor/${processorId}`);
    return res.data.id;
  } catch (err) {
    return err.res.data;
  }
});

export const initialState = {
  data: [],
  isLoading: false,
  isError: false
}

export const processorsSlice = createSlice({
  name: 'processors',
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder.addCase(createProcessor.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(createProcessor.fulfilled, (state, { payload }) => {
      let result_data = state.data.slice(0);
      result_data.push(payload);
      return {
        ...state,
        isLoading: false,
        data: result_data,
      };
    })
    builder.addCase(createProcessor.rejected, (state) => {
      state.isLoading = false;
      state.isError = false;
    })
    builder.addCase(updateProcessor.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(updateProcessor.fulfilled, (state, { payload }) => {
      const { id } = payload;
      const replaceIndex = state.data.findIndex(item => item.id === id);
      const newData = [...state.data];
      newData.splice(replaceIndex, 1, payload);
      return {
        ...state,
        data: newData,
        isLoading: false
      }
    })
    builder.addCase(updateProcessor.rejected, (state) => {
      state.isLoading = false;
      state.isError = false;
    })
    builder.addCase(deleteProcessor.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(deleteProcessor.fulfilled, (state, { payload }) => {
      return {
        ...state,
        data: state.data.filter(item => item.id !== payload),
        isLoading: false
      };
    })
    builder.addCase(deleteProcessor.rejected, (state) => {
      state.isLoading = false;
      state.isError = false;
    })
  }
})

export const processorsActions = processorsSlice.actions

export default processorsSlice.reducer