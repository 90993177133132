import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosAPI from "app/config/axiosAPI";

export const getReports = createAsyncThunk("getReports", async (params) => {
  try {
    const res = await axiosAPI.get(`/report`, { params });
    return res.data;
  } catch (err) {
    return err.res.data;
  }
});

export const initialState = {
  features: {},
  reportModules: {},
  requestDTO: {},
  isLoading: false,
  isError: false
}

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder.addCase(getReports.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(getReports.fulfilled, (state, { payload }) => {
      return {
        ...state,
        ...payload,
        isLoading: false,
      }
    })
    builder.addCase(getReports.rejected, (state) => {
      state.isLoading = false;
      state.isError = false;
    })
  }
})

export const reportsActions = reportsSlice.actions

export default reportsSlice.reducer