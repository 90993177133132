import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosAPI from "app/config/axiosAPI";

export const getAllDeviceTypes = createAsyncThunk("getAllDeviceTypes", async () => {
  try {
    const res = await axiosAPI.get(`/device/types`);
    return res.data;
  } catch (err) {
    return err.res.data;
  }
});

export const initialState = {
  data: [],
  isLoading: false,
  isError: false
}

export const deviceTypesSlice = createSlice({
  name: 'deviceTypes',
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder.addCase(getAllDeviceTypes.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(getAllDeviceTypes.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    })
    builder.addCase(getAllDeviceTypes.rejected, (state) => {
      state.isLoading = false;
      state.isError = false;
    })
  }
})

export const deviceTypesActions = deviceTypesSlice.actions

export default deviceTypesSlice.reducer