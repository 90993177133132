import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosAPI from "app/config/axiosAPI";
import jwtAuthService from "../../services/jwtAuthService";

export const getUserData = createAsyncThunk("getUserData", async () => {
  try {
    const res = await axiosAPI.get(`/user/current`);
    return res.data;
  } catch (err) {
    return err.res.data;
  }
});

export const updateUserName = createAsyncThunk("updateUserName", async (data) => {
  try {
    const res = await axiosAPI.put(`/user/currentname`, data);
    return res.data;
  } catch (err) {
    return err.res.data;
  }
});

export const updateUserPassword = createAsyncThunk("updateUserPassword", async (data) => {
  try {
    const res = await axiosAPI.put(`/user/currentpassword`, data);
    return res.data;
  } catch (err) {
    return err.res.data;
  }
});

export const initialState = {};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, { payload }) => {
      state = payload;
      return payload;
    },
    logoutUser: () => {
      jwtAuthService.logout();
      window.location.href = `${process.env.REACT_APP_BASE_URL}/signin`;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUserData.fulfilled, (state, { payload }) => {
      jwtAuthService.setUser(payload);
      return {
        ...state,
        ...payload
      }
    })
    builder.addCase(updateUserName.fulfilled, (state, { payload }) => {
      jwtAuthService.setUser(payload);
      return {
        ...state,
        ...payload
      }
    })
    builder.addCase(updateUserPassword.fulfilled, (state, { payload }) => {
      jwtAuthService.setUser(payload);
      return {
        ...state,
        ...payload
      }
    })
  }
})

export const { setUserData, logoutUser } = userSlice.actions

export default userSlice.reducer