import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosAPI from "app/config/axiosAPI";

export const getLinkedAccounts = createAsyncThunk("getLinkedAccounts", async (id) => {
  try {
    const res = await axiosAPI.get('/linkedaccount');
    return res.data;
  } catch (err) {
    return err.res.data;
  }
});

export const deleteLinkedAccount = createAsyncThunk("deleteLinkedAccount", async (id) => {
  try {
    const res = await axiosAPI.delete(`/linkedaccount/${id}`);
    return res.data.id;
  } catch (err) {
    return err.res.data;
  }
});

export const initialState = {
  data: [],
  isLoading: false,
  isError: false
}

export const linkedAccountsSlice = createSlice({
  name: 'linkedAccounts',
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder.addCase(getLinkedAccounts.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(getLinkedAccounts.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    })
    builder.addCase(getLinkedAccounts.rejected, (state) => {
      state.isLoading = false;
      state.isError = false;
    })
    builder.addCase(deleteLinkedAccount.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(deleteLinkedAccount.fulfilled, (state, { payload }) => {
      return {
        ...state,
        data: state.data.filter(item => item.id !== payload),
        isLoading: false
      };
    })
    builder.addCase(deleteLinkedAccount.rejected, (state) => {
      state.isLoading = false;
      state.isError = false;
    })
  }
})

export const linkedAccountsActions = linkedAccountsSlice.actions

export default linkedAccountsSlice.reducer