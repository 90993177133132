import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import jwtAuthService from "../../services/jwtAuthService";
import { getUserData } from "./userSlice";

export const loginWithEmailAndPassword = createAsyncThunk("loginWithEmailAndPassword", async ({ email, password, history }, { dispatch }) => {
  try {
    const res = await jwtAuthService.loginWithEmailAndPassword(email, password);
    dispatch(getUserData());
    return res;
  } catch (err) {
    return {};
  }
});

export const initialState = {
  success: false,
  loading: false,
  error: {}
}

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder.addCase(loginWithEmailAndPassword.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(loginWithEmailAndPassword.fulfilled, (state) => {
      return {
        ...state,
        success: true,
        loading: false
      };
    })
    builder.addCase(loginWithEmailAndPassword.rejected, (state, { payload }) => {
      return {
        ...state,
        success: false,
        loading: false,
        error: payload
      };
    })
  }
})

export const loginActions = loginSlice.actions

export default loginSlice.reducer