import React from 'react';

function BoxTitle(props) {
	const { title, style } = props;

	return (
		<div className="flex box-title-component">
			<div
				className="box"
				style={style}
			/>
			<div className="title">{title}</div>
		</div>
	);
}

export default BoxTitle;
