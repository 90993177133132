import axiosAPI from 'app/config/axiosAPI';
import localStorageService from "./localStorageService";

class JwtAuthService {

  loginWithEmailAndPassword = (email, password) => {
    return axiosAPI.post(`/authenticate`,
      {
        username: email,
        password: password
      }
    ).then(res => {
      this.setUser({ email });
      this.setSession(res.data.jwt);
      return res.data.jwt;
    });
  };

  loginWithToken = () => {
    return new Promise((resolve, reject) => {
    }).then(data => {
      return data;
    });
  };

  logout = () => {
    this.setSession(null);
    this.removeUser();
  }

  setSession = token => {
    if (token) {
      localStorage.setItem("jwt_token", token);
    } else {
      localStorage.removeItem("jwt_token");
      delete axiosAPI.defaults.headers.common["Authorization"];
    }
  };

  setUser = (user) => {
    localStorageService.setItem("auth_user", user);
  }

  getUser = () => {
    return localStorageService.getItem("auth_user");
  }
  
  removeUser = () => {
    localStorage.removeItem("auth_user");
  }
}

export default new JwtAuthService();
