import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosAPI from "app/config/axiosAPI";

export const getAllDevices = createAsyncThunk("getAllDevices", async (locationId) => {
  try {
    const res = await axiosAPI.get(`/location/${locationId}/devices`);
    return res.data;
  } catch (err) {
    return err.res.data;
  }
});

export const createDevice = createAsyncThunk("createDevice", async (device) => {
  try {
    const res = await axiosAPI.post('/devices', {
      ...device
    });
    return res.data;
  } catch (err) {
    return err.res.data;
  }
});

export const updateDevice = createAsyncThunk("updateDevice", async (device) => {
  try {
    const res = await axiosAPI(
      {
        method: 'put',
        url: `/device/${device.id}`,
        data: { ...device }
      }
    );
    return res.data;
  } catch (err) {
    return err.res.data;
  }
});

export const deleteDevice = createAsyncThunk("deleteDevice", async (deviceId) => {
  try {
    const res = await axiosAPI.delete(`/device/${deviceId}`);
    return res.data.id;
  } catch (err) {
    return err.res.data;
  }
});

export const initialState = {
  data: [],
  isLoading: false,
  isError: false
}

export const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder.addCase(getAllDevices.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(getAllDevices.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    })
    builder.addCase(getAllDevices.rejected, (state) => {
      state.isLoading = false;
      state.isError = false;
    })
    builder.addCase(createDevice.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(createDevice.fulfilled, (state, { payload }) => {
      let result_data = state.data.slice(0);
      result_data.push(payload);
      return {
        ...state,
        isLoading: false,
        data: result_data,
      };
    })
    builder.addCase(createDevice.rejected, (state) => {
      state.isLoading = false;
      state.isError = false;
    })
    builder.addCase(updateDevice.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(updateDevice.fulfilled, (state, { payload }) => {
      const { id } = payload;
      const replaceIndex = state.data.findIndex(item => item.id === id);
      const newData = [...state.data];
      newData.splice(replaceIndex, 1, payload);
      return {
        ...state,
        data: newData,
        isLoading: false
      }
    })
    builder.addCase(updateDevice.rejected, (state) => {
      state.isLoading = false;
      state.isError = false;
    })
    builder.addCase(deleteDevice.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(deleteDevice.fulfilled, (state, { payload }) => {
      return {
        ...state,
        data: state.data.filter(item => item.id !== payload),
        isLoading: false
      };
    })
    builder.addCase(deleteDevice.rejected, (state) => {
      state.isLoading = false;
      state.isError = false;
    })
  }
})

export const devicesActions = devicesSlice.actions

export default devicesSlice.reducer