import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosAPI from "app/config/axiosAPI";

export const getAllTags = createAsyncThunk("getAllTags", async () => {
  try {
    const res = await axiosAPI.get(`/tags`);
    return res.data;
  } catch (err) {
    return err.res.data;
  }
});

export const createTag = createAsyncThunk("createTag", async (tag) => {
  try {
    const res = await axiosAPI.post('/tags', {
      ...tag
    });
    return res.data;
  } catch (err) {
    return err.res.data;
  }
});

export const deleteTag = createAsyncThunk("deleteTag", async (tagId) => {
  try {
    const res = await axiosAPI.delete(`/tags`, {params: { name: tagId }});
    return res.data.id;
  } catch (err) {
    return err.res.data;
  }
});

export const initialState = {
  data: [],
  isLoading: false,
  isError: false
}

export const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder.addCase(getAllTags.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(getAllTags.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    })
    builder.addCase(getAllTags.rejected, (state) => {
      state.isLoading = false;
      state.isError = false;
    })
    builder.addCase(createTag.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(createTag.fulfilled, (state, { payload }) => {
      let result_data = state.data.slice(0);
      result_data.push(payload);
      return {
        ...state,
        isLoading: false,
        data: result_data,
      };
    })
    builder.addCase(createTag.rejected, (state) => {
      state.isLoading = false;
      state.isError = false;
    })
    builder.addCase(deleteTag.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(deleteTag.fulfilled, (state, { payload }) => {
      return {
        ...state,
        data: state.data.filter(item => item.id !== payload),
        isLoading: false
      };
    })
    builder.addCase(deleteTag.rejected, (state) => {
      state.isLoading = false;
      state.isError = false;
    })
  }
})

export const tagsActions = tagsSlice.actions

export default tagsSlice.reducer