import { EgretLoadable } from 'egret';

const Dashboard = EgretLoadable({
	loader: () => import('./Dashboard'),
});

const dashboardRoutes = [
	{
		path: '/dashboard',
		exact: true,
		component: Dashboard,
	}
];

export default dashboardRoutes;
