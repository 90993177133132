import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS, LABEL_POSITION } from '../../app/config';

function LinearBar(props) {
	const { valueLabel, showValue, labelStyle, position = LABEL_POSITION.CENTER, style, className } = props;
	const useStyles = makeStyles(() => ({
		root: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: position === LABEL_POSITION.CENTER ? 'center' : 'flex-end',
			textAlign: position === LABEL_POSITION.CENTER ? 'center' : 'right',
			width: '100%',
			height: 15,
			borderRadius: 3,
			background: COLORS.SUCCESS,
			'.value': {
				position: position === LABEL_POSITION.CENTER ? 'absolute' : 'relative'
			}
		}
	}));

	const classes = useStyles();

	return (
		<div className={`${className} w-100 linear-bar-component`}>
			<div
				className={classes.root}
				style={style}
			>
				{showValue && (position === LABEL_POSITION.CENTER || position === LABEL_POSITION.INSIDE) && <span className="value" style={labelStyle}>{`${valueLabel}`}</span>}
			</div>
			{showValue && (position === LABEL_POSITION.TOP || position === LABEL_POSITION.RIGHT) && <span className="value" style={labelStyle}>{`${valueLabel}`}</span>}
		</div>
	);
}

export default LinearBar;
